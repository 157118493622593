/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDataContext } from '../context/DataProvider'
import styles from './GenericFormWelcome.module.css'
import { LoadingIndicator } from 'components/LoadingIndicator'
import {
  Checkbox,
  FormControlLabel,
  Grow,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  dropdownItemStyles,
  dropdownOutlineLabelStyles,
  textFieldOutlineLabelStyles,
} from 'utils/MuiStyles'
import { useEffect, useRef, useState } from 'react'
import { isEmail } from 'utils/stringUtils'
import { isLocal } from 'utils/EnvUtils'

type PageProps = {
  onContinue: () => void
}

export default function GenericFormWelcome(props: PageProps) {
  const { submission, setSubmission, program } = useDataContext()
  const [rerenderCount, setRerenderCount] = useState(0)
  const shouldValidate = useRef(false)

  // console.log('GenericFormWelcome:', submission)

  const getFirstNameErrorMessage = () => {
    if (shouldValidate.current && submission?.firstName?.trim() === '') {
      return 'Please provide your name*'
    } else return ''
  }

  const getJobTitleErrorMessage = () => {
    if (shouldValidate.current && submission?.jobTitle?.trim() === '') {
      return 'Please provide your job title*'
    } else return ''
  }

  const getOrganisationErrorMessage = () => {
    if (shouldValidate.current && submission?.organisation?.trim() === '') {
      return 'Please provide your organisation*'
    } else return ''
  }

  const getEmailErrorMessage = () => {
    if (!shouldValidate.current) {
      return ''
    }
    const email = submission?.email || ''
    if (!isEmail(email)) {
      return 'Please provide your email address*'
    }
    return ''
  }

  const getPhoneNumberErrorMessage = () => {
    if (shouldValidate.current && submission?.phoneNumber?.trim() === '') {
      return ''
    } else return ''
  }

  const getNumberOfLearnersErrorMessage = () => {
    if (shouldValidate.current && submission?.numberOfLearners?.trim() === '') {
      return 'Please provide the number of learners*'
    }
    return ''
  }

  const getPreferredCourseDateErrorMessage = () => {
    if (shouldValidate.current && !submission?.preferredCourseDate) {
      return 'Please select a preferred course date*'
    } else return ''
  }

  const getTncError = () => {
    if (shouldValidate.current && !submission?.tncAgreeAt) {
      return true
    } else return false
  }

  const label = (
    <span>
      I agree to the terms of Mentem's{' '}
      <span style={{ textDecoration: 'underline' }}>
        <a href="https://www.mentem.co/privacy/">Privacy Policy</a>
      </span>
    </span>
  )

  const readyForSubmit = (): boolean => {
    if (submission?.firstName?.trim() === '') {
      return false
    }
    if (submission?.jobTitle?.trim() === '') {
      return false
    }
    if (submission?.organisation?.trim() === '') {
      return false
    }
    if (!isEmail(submission?.email || '')) {
      return false
    }
    if (submission?.numberOfLearners?.trim() === '') {
      return false
    }
    if (submission?.numberOfLearners?.trim() === '') {
      return false
    }
    if (!submission?.preferredCourseDate) {
      return false
    }
    if (!submission?.tncAgreeAt) {
      return false
    }
    return true
  }

  const handleClickSubmit = () => {
    shouldValidate.current = true

    if (readyForSubmit()) {
      shouldValidate.current = false
      props.onContinue()
    } else {
      console.log('Invalid profile')
      setRerenderCount(rerenderCount + 1)
    }
  }

  // For testing purposes
  // useEffect(() => {
  //   if (isLocal) {
  //     setSubmission({
  //       ...submission!,
  //       firstName: 'Key',
  //       jobTitle: 'Developer',
  //       organisation: 'Mentem',
  //       email: 'key.hui@mentem.co',
  //       phoneNumber: '1234567890',
  //       numberOfLearners: '5-10',
  //       preferredCourseDate: '19 Aug - 11 Oct 2024',
  //       tncAgreeAt: new Date(),
  //     })
  //   }
  // }, [setSubmission, submission])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description} style={{ fontSize: '26px' }}>
          {program?.components?.sessionForm?.title || ''}
        </div>
        <div className={styles.instructions}></div>
        <div className={styles.formcontainer}>
          <TextField
            value={submission?.firstName || ''}
            label={getFirstNameErrorMessage() || 'Name'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, firstName: e.target.value })
            }}
            error={getFirstNameErrorMessage() !== ''}
          />

          <TextField
            value={submission?.jobTitle || ''}
            label={getJobTitleErrorMessage() || 'Job title'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, jobTitle: e.target.value })
            }}
            error={getJobTitleErrorMessage() !== ''}
          />

          <TextField
            value={submission?.organisation || ''}
            label={getOrganisationErrorMessage() || 'Organisation'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, organisation: e.target.value })
            }}
            error={getOrganisationErrorMessage() !== ''}
          />

          <TextField
            value={submission?.email || ''}
            label={getEmailErrorMessage() || 'Email'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getEmailErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({ ...submission!, email: e.target.value })
            }}
          />

          <TextField
            value={submission?.phoneNumber || ''}
            label={getPhoneNumberErrorMessage() || 'Phone number (optional)'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getPhoneNumberErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({ ...submission!, phoneNumber: e.target.value })
            }}
          />

          <TextField
            value={submission?.numberOfLearners || ''}
            label={
              getNumberOfLearnersErrorMessage() ||
              'Number of learners you plan to enrol'
            }
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getNumberOfLearnersErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({
                ...submission!,
                numberOfLearners: e.target.value,
              })
            }}
          />

          <Select
            style={{
              width: '100%',
              border: '1px solid rgba(230, 230, 230, 1)',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
            variant="outlined"
            sx={dropdownOutlineLabelStyles}
            displayEmpty
            renderValue={(selected) => {
              const errorMessage = getPreferredCourseDateErrorMessage()
              return errorMessage ? (
                <div style={{ color: 'rgba(211,47,41,1)' }}>{errorMessage}</div>
              ) : submission?.preferredCourseDate ? (
                <div style={{ color: 'rgba(0,0,0,1)' }}>
                  {submission?.preferredCourseDate}
                </div>
              ) : (
                <div style={{ color: 'rgba(0,0,0,0.6)' }}>
                  Preferred course date
                </div>
              )
            }}
            value={submission?.preferredCourseDate || ''}
            label="Preferred course date"
            onChange={(e) => {
              setSubmission({
                ...submission!,
                preferredCourseDate: e.target.value,
              })
            }}
          >
            {program?.components?.sessionForm?.preferredCourseDates?.map(
              (date) => (
                <MenuItem value={date} sx={dropdownItemStyles} key={date}>
                  {date}
                </MenuItem>
              ),
            )}
          </Select>

          <div className={styles.checkboxcontainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={submission?.tncAgreeAt ? true : false}
                  sx={{
                    '&.Mui-checked': {
                      color: 'rgba(186, 97, 255, 1)',
                    },
                  }}
                  onChange={(e) => {
                    setSubmission({
                      ...submission!,
                      tncAgreeAt: e.target.checked ? new Date() : undefined,
                    })
                  }}
                />
              }
              label={label}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28 },
              }}
            />
            <Grow in={getTncError()} style={{ transformOrigin: '0 50% 0' }}>
              <span className={styles.error}>Required</span>
            </Grow>
          </div>

          {submission?.program ? (
            <button
              onClick={handleClickSubmit}
              className={
                readyForSubmit()
                  ? styles.submitButtonPurple
                  : styles.submitButton
              }
            >
              Submit
            </button>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  )
}
