import { useDataContext } from '../context/DataProvider'
import styles from './GenericHome.module.css'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '@egjs/react-flicking/dist/flicking.css'
import { GenericSessionHero } from 'components/GenericSessionHero'
import { GenericSessionCTA } from 'components/GenericSessionCTA'
import { GenericSessionSwiper } from 'components/GenericSessionSwiper'
import GenericAppBar from 'components/GenericAppBar'
import { GenericSessionVideo } from 'components/GenericSessionVideo'
import { GenericSessionSchedule } from 'components/GenericSessionSchedule'
import { GenericSessionScheduleTable } from 'components/GenericSessionScheduleTable'
import { GenericSessionBlocks } from 'components/GenericSessionBlocks'
import { GenericSessionBox } from 'components/GenericSessionBox'
import { GenericSessionFaq } from 'components/GenericSessionFaq'
import { Helmet } from 'react-helmet'
import { GenericLoadingIndicator } from 'components/GenericLoadingIndicator'
import { GenericFooter } from 'components/GenericFooter'
import GenericAppBarV2 from 'components/GenericAppBarV2'
import { GenericImageBox } from 'components/GenericImageBox'
import { GenericProgram } from 'components/GenericProgram'
import React from 'react'

interface Props {
  clientCode: string
  programCode: string
}

export default function GenericHome(props: Props) {
  const { checkProgram, program } = useDataContext()
  const { clientCode, programCode } = useParams()

  useEffect(() => {
    let tmpClientCode = clientCode || window.location.pathname.split('/')[1]
    let tmpProgramCode = programCode || window.location.pathname.split('/')[2]

    // override from props
    if (props.clientCode) {
      tmpClientCode = props.clientCode
    }
    if (props.programCode) {
      tmpProgramCode = props.programCode
    }

    if (!tmpClientCode || !tmpProgramCode) {
      console.error('Client code or program code is missing')
    }
    console.log('Home:', tmpClientCode, tmpProgramCode)
    checkProgram(tmpClientCode, tmpProgramCode)
  }, [checkProgram, clientCode, programCode, program, props])

  const version = program?.components?.version || 1

  switch (version) {
    case 1:
      return (
        <div id="main" className={styles.container}>
          {program && program?.name && (
            <Helmet>
              <title>{program?.name}</title>
            </Helmet>
          )}
          <GenericAppBar data={program?.components?.sessionAppBar} />
          {program && (
            <>
              <div id="content" style={{ position: 'relative' }}>
                <GenericSessionHero
                  title={program?.name || ''}
                  subtitle={program?.components?.sessionHero.subtitle || ''}
                  background={program?.components?.sessionHero.background || ''}
                  color={program?.components?.sessionHero.color || ''}
                />

                <GenericSessionCTA
                  title={program?.components?.sessionCTA.title || ''}
                  subtitle={program?.components?.sessionCTA.subtitle || ''}
                  description={
                    program?.components?.sessionCTA.description || ''
                  }
                  buttonText={program?.components?.sessionCTA.buttonText || ''}
                />

                <GenericSessionVideo
                  title={program?.components?.sessionVideo.title || ''}
                  description={
                    program?.components?.sessionVideo.description || ''
                  }
                  videoThumbnail={
                    program?.components?.sessionVideo.videoThumbnail || ''
                  }
                  videoUrl={program?.components?.sessionVideo.videoUrl || ''}
                />

                {/* background image */}
                <img
                  src="https://content.mentem.co/eoi/_share/top.jpg"
                  alt="top"
                  style={{ position: 'absolute', width: '100%' }}
                />

                <GenericSessionSchedule
                  title={program?.components?.sessionSchedule.title || ''}
                  description={
                    program?.components?.sessionSchedule.description || ''
                  }
                />

                <GenericSessionScheduleTable
                  id={program?.components?.sessionScheduleTable.id || ''}
                  imageUrl={
                    program?.components?.sessionScheduleTable.imageUrl || ''
                  }
                  title={program?.components?.sessionScheduleTable.title || ''}
                  head={program?.components?.sessionScheduleTable.head || {}}
                  rows={program?.components?.sessionScheduleTable.rows || []}
                />

                <GenericSessionBlocks
                  brochureTitle={
                    program?.components?.sessionBrochure.title || ''
                  }
                  brochureDescription={
                    program?.components?.sessionBrochure.description || ''
                  }
                  brochureThumbnail={
                    program?.components?.sessionBrochure.brochureThumbnail || ''
                  }
                  brochurePdf={
                    program?.components?.sessionBrochure.brochurePdf || ''
                  }
                  syllabusPdf={
                    program?.components?.sessionBrochure.syllabusPdf || ''
                  }
                  items={program?.components?.sessionDemoTopics.items || []}
                />
              </div>

              <GenericSessionSwiper
                id="testimonials"
                title={program?.components?.sessionSwiper.title || ''}
                items={program?.components?.sessionSwiper.items || []}
              />

              <GenericSessionBox
                title={program?.components?.sessionBox.title || ''}
                content1={program?.components?.sessionBox.content1 || ''}
                content2={program?.components?.sessionBox.content2 || ''}
                buttonText={program?.components?.sessionBox.buttonText || ''}
              />

              <GenericSessionFaq
                items={program?.components?.sessionFaq.items || []}
              />
              <GenericFooter backgroundColor="#000" />
            </>
          )}
          {!program && (
            <div className={styles.center}>
              <GenericLoadingIndicator />
            </div>
          )}
        </div>
      )
    case 2:
      return (
        <div id="main" className={styles.container}>
          <Helmet>
            <title>{program?.name}</title>
          </Helmet>

          <GenericAppBarV2 data={program?.components?.sessionAppBar} />
          <GenericSessionHero
            title={program?.name || ''}
            subtitle={program?.components?.sessionHero.subtitle || ''}
            background={program?.components?.sessionHero.background || ''}
            color={program?.components?.sessionHero.color || ''}
          />

          <GenericSessionCTA
            title={program?.components?.sessionCTA?.title || ''}
            subtitle={program?.components?.sessionCTA?.subtitle || ''}
            description={program?.components?.sessionCTA?.description || ''}
            buttonText={program?.components?.sessionCTA?.buttonText || ''}
          />

          <GenericSessionVideo
            title={program?.components?.sessionVideo?.title || ''}
            description={program?.components?.sessionVideo?.description || ''}
            videoThumbnail={
              program?.components?.sessionVideo?.videoThumbnail || ''
            }
            videoUrl={program?.components?.sessionVideo?.videoUrl || ''}
          />

          <GenericImageBox
            title={program?.components?.sessionImageBox?.title || ''}
            titleColor={program?.components?.sessionImageBox?.titleColor || ''}
            imageUrl={program?.components?.sessionImageBox?.imageUrl || ''}
            background={program?.components?.sessionImageBox?.background || ''}
          />

          {/* programs here */}
          {program?.components?.sessionPrograms?.map((p, index) => (
            <React.Fragment key={`program-${index}`}>
              <GenericProgram data={p} />
              <div
                style={{
                  height: '60px',
                }}
              />
            </React.Fragment>
          ))}

          <GenericSessionSwiper
            id={program?.components?.sessionSwiper.id || ''}
            title={program?.components?.sessionSwiper.title || ''}
            items={program?.components?.sessionSwiper.items || []}
          />

          <GenericSessionFaq
            items={program?.components?.sessionFaq?.items || []}
            questionColor={program?.components?.sessionFaq?.questionColor || ''}
            answerColor={program?.components?.sessionFaq?.answerColor || ''}
            background={program?.components?.sessionFaq?.background || ''}
          />

          <GenericFooter
            backgroundColor={
              program?.components?.footer?.backgroundColor || '#000'
            }
            note={program?.components?.footer?.note || ''}
          />

          {!program && (
            <div className={styles.center}>
              <GenericLoadingIndicator />
            </div>
          )}
        </div>
      )
    default:
      return <div></div>
  }
}
